import React, { useState } from "react"

import "./index.css"

import { Loader } from "../components/rsvp/form/loader"
import { Menu } from "../components/menu"
import { RegistryBackground } from "../components/registry/background"
import SEO from "../components/seo"
import { useInitialLoad } from "../hooks/use-initial-load"

const Page = (props) => {
  const { location } = props
  const [videoLoaded, setVideoLoaded] = useState(false)

  const initialLoad = useInitialLoad()

  return (
    <>
      <div className="container overlay"/>
      <div className="animate-blur container" style={{ filter: "blur(0px)" }}>
        <RegistryBackground
          className="animate-opacity full-page-background"
          style={{ opacity: initialLoad ? 1 : 0 }}
        />
      </div>
      <Menu location={location}/>
      <div
        className="animate-opacity the-big-day-hero text"
        style={{ opacity: initialLoad ? 1 : 0, overflowY: "scroll" }}
      >
        <div>#WeAreAbel Live Stream</div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ marginBottom: "8px", maxWidth: "500px", textAlign: "center" }}>
            The live stream may take some time to load... <br/>
          </div>
          <div style={{ maxWidth: "500px", textAlign: "center" }}>
            <a
              className="hover-button"
              href="https://www.facebook.com/edriennemanalastas/videos/10221832347299894/?t=7424"
              rel="noopener noreferrer"
              target="_blank"
            >
              If you have any issues, click here to view it on Facebook
            </a>
          </div>
        </div>

        {!videoLoaded && (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "64px" }}>
            <div style={{ width: "500px"}}>
              <Loader />
            </div>
          </div>
        )}

        <div
          style={{ overflowX: "scroll" }}
        >
          <iframe
            className="animate-opacity"
            onLoad={() => setVideoLoaded(true)}
            src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fedriennemanalastas%2Fvideos%2F10221832347299894%2F&width=1280&t=7425"
            width="100%"
            height="720"
            style={{
              border: "none",
              opacity: initialLoad && videoLoaded ? 1 : 0,
              overflow: "hidden",
              maxWidth: "1080px"
            }}
            scrolling="no"
            frameBorder="0"
            allowtransparency="true"
            allowFullScreen={true}
            title="We Are Abel - Live Stream"
          />
        </div>
      </div>
    </>
  )
}

const IndexPage = (props) => (
  <>
    <SEO title="The Big Day"/>
    <Page location={props.location}/>
  </>
)

export default IndexPage
